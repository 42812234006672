.confirmation .title {
  font-size: 1.25rem;
  font-weight: var(--semibold);
}

.confirmation button {
  font-size: 1.125rem;
  padding: 0.875rem 1.875rem;
  margin-block: 1.875rem;
  border-radius: 10px;
}

.confirmation .button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation .link {
  color: var(--bs-blue);
  text-decoration: underline;
}

.confirmation .form-check {
  width: max-content;
}

.confirmation input[type="checkbox"]:checked {
  background-color: rgb(var(--bs-success-rgb));
  border-color: rgb(var(--bs-success-rgb));
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}